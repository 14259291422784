// import our bootstrap config and styles
@import "custom";

// styles in base.scss
@import "base";

/*********************
TABLET & SMALLER LAPTOPS
*********************/
@media only screen and (min-width: 768px) {
  // styles in 768up.scss
  @import "768up";
}

// end of media query

/*********************
LAPTOPS AND LOW-RES DESKTOP
*********************/
@media only screen and (min-width: 992px) {
  @import "992up";
}

// end of media query

/*********************
STANDARD DESKTOP
*********************/
@media only screen and (min-width: 1200px) {
  @import "1200up";
}

// end of media query

/*********************
XXL DESKTOP
*********************/
@media only screen and (min-width: 1400px) {
  @import "1400up";
}

// end of media query
