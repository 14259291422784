body {
  line-height: 24px;
}

.container-md {
  padding-left:0;
  padding-right:0;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}
h1 {
  font-family: $paytonone;
  font-weight: 500;
  font-style: normal;
  line-height:1
}

h4 {
  line-height: 1.4 !important;
}

.navbar-brand {
  margin-left:40px;
}
.brand-img {
  max-width: 41px;
  height: auto;
}
.navbar-toggler-container {
  position: absolute;
  top: 54px;
  right: 20px;
}
.navbar-light .navbar-toggler {
  border: none;
}
.navbar-light .navbar-toggler-icon {
  background-image: url(../images/menu.png);
}
.nav-item {
  text-transform: uppercase;
}
img,
video {
  max-width: 100%;
}

//social menu and icons

.socials-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    a {
      width: 32px;
      height: 25px;
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      transition: all 0.3s ease;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .discord {
    background-image: url(../images/discord-2.svg);
  }
  .opensea {
    background-image: url(../images/opensea-2.svg);
  }
  .twitter {
    background-image: url(../images/twitter-icon-2.svg);
  }
  .twitter.black {
    background-image: url(../images/twitter-icon-black.svg);
  }
  .discord.black {
    background-image: url(../images/discord.svg);
  }
  .opensea.black {
    background-image: url(../images/opensea-black.svg);
  }
}

.social-icon {
  max-height:30px; 
  display:inline-block;
  margin-right:10px;
}

//buttons
.btn,
.btn:focus {
  text-transform: uppercase;
  border-radius: 30px;
  padding: 1rem 3rem;
  border: none;
  font-size: 1em;
}
.btn-primary,
.btn-primary:focus {
  font-weight: 600;
  color: $white;
  border: 2px solid $primary;
  &:hover {
    color: $primary;
    background: transparent;
  }
}
.btn-dark,
.btn-dark:focus {
  font-weight: 600;
  color: #fcf3e9;
  border: 2px solid $dark;
  &:hover {
    background: transparent;
    border: 2px solid #fcf3e9;
  }
}

/* Padding Styles */ 
.inner-content {
  padding:62px 40px;
}

//navbar
.navbar {
  background-color:#f57400;
  height:100px;
}

.navbar-nav {
  width:75%;
  margin:80px auto;
}

#navbarSupportedContent {
  background-color: #f57400;
}
.navbar-light .navbar-nav {
  font-size: 0.813em;
  letter-spacing: 1.04px;
  
  .nav-link {
    color: #fff;
    opacity: 1;
    font-family: "Paytone One", sans-serif;
    font-size: 2.4rem;
    line-height:1.1;
  }
}

//home hero
.home-hero {
  display: flex;
  width: 100%;
  height: calc(100vh - 100px);
  color: #ebebeb;
  background-color: #f57400;
  background-image: url(../images/mk-header-mobile.png);
  background-size: 900px auto;
  background-repeat: no-repeat;
  background-position: bottom center;
}
.home-hero-content {
  color: #fff;
  padding-left:40px;
  .hero-title {
    line-height: 1;
    letter-spacing: -1px;
    
  }
}

// second section with socials links
#socials-banner {
  background-color: #151515;
  color: #fcf3e9;
  a {
    color: #fcf3e9;
  }
}
.socials-banner-content {
  padding: 44px 40px;
}

/* Join discord */
#join-discord {
  background-color:#f1f1f1;
}

.nft-collection { 
  display: grid;
  grid-template-columns: 50% 50%;

  img {
    max-width: 100%;
  }
}

.join-discord-content {
  padding:62px 40px
}

/* Merch Store */
#merch-banner {
  background-color: #53d6e4;

  h2 {
    span {
      color: #ffffff;
    }
  }
}

.merch-promo {
  padding-top: 4rem;
}

.merch-icons img {
  max-height: 30px;
  display: inline-block;
  margin-right: 8px;
}

/* The Team */ 

.team-grid {
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 30px;
  
}
.team-card {
  border-radius: 24px;
  background-color:#fff;
  padding:40px 30px 0;
  margin-bottom: 2rem;
}

.team-card-alt {
  background-color:#4f001f;
  border:1px solid #4f001f;
}

.team-content {
  padding-left: 1rem;
  padding-right: 1rem;
  
  h3 {
    font-size: 1.5rem;
  }
}

.team-yellow {
  background-color:#f6de2d
}

.team-red {
  background-color:#d6393a;
  color:#f7f1ec;
}

.team-grey {
  background-color:#444444;
  color:#fff;
}

.team-light-grey {
  background-color:#f7f1ec;
}

.team-avatar-wrapper {
  position:relative;
  max-width:200px;
  margin:3rem auto 0;

  
  img {
    max-width: 100%;
  }
  .team-icon {
    position:absolute;
    display: inline-block;
    z-index: 9;
    top: 0;
    left:0;
  }

  .team-avatar {
    position: relative;
    z-index: 10;
  }
}

/* Rotating Card Sections */

.card-slider.container-fluid {
  width: calc(((100% - 350px) / 2) + 576px) !important;
  margin-right: -20px;
}

// slider control dots
.rmnav-container {
  display: none;
}

.card-slider {
  .tns-outer {
    margin-left: -15px;
    position: relative;

    button {
      padding: 0 !important;
    }
  }
  .tns-item {
    .card {
      background-color: $dark;
      background-image: url(../images/mk-icon.svg);
      background-size: 40px auto;
      background-repeat: no-repeat;
      background-position: 92% 8%;
      color: #d3d3d3;
      border-radius: 0;
      border: none;
      min-height: 420px;
      margin: 0 15px;
      -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 93%);
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 93%);
      padding: 30px 0;
      line-height: 1.4;
      .card-body {
        padding: 40px;
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
      }
    }
    &:nth-child(2n + 1) .card {
      -webkit-clip-path: polygon(0 7%, 100% 0%, 100% 100%, 0% 100%);
      clip-path: polygon(0 7%, 100% 0%, 100% 100%, 0% 100%);
      background-position: 92% 92%;
      .card-body {
        justify-content: flex-start;
      }
    }
    &:first-child {
      .card {
        margin-left: 0;
      }
    }
  }
  .rm-stage {
    color: #ffce03;
    font-size: 1.125rem;
    font-weight: 600;
    font-family: $font-family-base;
    margin-bottom: 1.5rem;
  }
  .rm-title {
    font-size: 1.375rem;
    line-height: 1.4;
    margin-bottom: 1.5rem;
  }
}

#roadmap {
  overflow-x: hidden;
}


/* FCATS (FAQs) */
#faqs {
  h2:not(.accordion-header), h4 {
    padding-left:10px;
    padding-right:10px;
  }
}

.faq-wrapper {
  padding-left: 40px;
  padding-right: 40px;
}

#faqs {
  background-image: url(../images/fcats-graphic@2x.png);
  background-repeat: no-repeat;
  background-size: 150px auto;
  background-position: bottom right;
  padding-bottom:240px;

  .accordion-button {
    padding:1rem 3rem 1rem 1rem;
    line-height:1.5;
  }
  .accordion-item,
  .accordion-button,
  .accordion-body {
    background: transparent;
    color: $white;
    border-left: none;
    border-right: none;
    border-color: rgba(255, 255, 255, 0.3);
    border-radius: 0;
  }
  .accordion-body {
    border: none;
    font-size: 0.875em;
  }
  .accordion-item:last-child {
    border-bottom: none;
  }
}
.accordion-button::after {
  background-image: none;
  content: "+";
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  position:absolute;
  right:10px;
}
.accordion-button:not(.collapsed)::after {
  background-image: none;
  transform: rotate(45deg);
  content: "+";
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary;
}

#footer {
  padding-top: 80px;
  .brand-img {
    max-width: 65px;
  }
  .brand-name {
    max-width: 133px;
  }
  .socials-nav {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    .twitter {
      background-image: url(../images/twitter-icon-black.svg);
    }
    .discord {
      background-image: url(../images/discord.svg);
    }
    .opensea {
      background-image: url(../images/opensea-black.svg);
    }
  }
  .footer-graphic {
    display: flex;
    margin: 100px auto 0 auto;
    max-width: 300px;
  }
}

@media (max-width: 992px) {
  .navbar .socials {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
