.brand-name {
  font-size: 0.625em;
}
//navbar
.navbar-light .navbar-nav {
  font-size: 0.813em;
}
.socials-nav {
  li {
    margin-left: 10px;
    margin-right: 10px;
    a {
      width: 32px;
    }
  }
}

.home-hero {
  align-items: center;
  background-image: url(../images/mk-header-graphic@2x.png);
  background-size: contain;
  background-position: bottom right;
  height:calc(100vh - 100px);
}

.home-hero-content {
  width:40%;
  padding-right: 0 !important;
}

/* Join Discord */ 
.discord-wrapper {
  min-height: 600px;
}

.nft-collection {
  width: 600px;
}

#socials-banner {
  min-height: 700px;
  background-size: auto 650px;
  h4 {
    font-size: 1.5rem;
  }
}
.join-discord-content {
  h4 {
    line-height: 1.4;
  }
}

.card-slider.container-fluid {
  width: calc((100% - 1200px) / 2 + 1200px) !important;
}
.card-slider {
  .team-img {
    max-width: 120px;
  }
  .team-bio {
    padding-right: 3rem;
  }
}
