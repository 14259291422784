.container, .container-md {
  max-width: 1140px;
}

.navbar-expand-lg .navbar-nav {
  .nav-link {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.home-hero-content {
  margin-left: 80px;
  .hero-title {
    font-size: 5.875rem;
    letter-spacing: -3px;
  }
}

.card-slider {
  .rm-stage,
  .rm-title {
    margin-bottom: 1.75rem;
  }
  .tns-item .card {
    margin: 0 20px;
    .card-body {
      padding: 60px;
    }
  }
}

/* Join Discord */ 
.discord-wrapper {
  min-height: 720px;
}

.nft-collection {
  width: 720px;
}
