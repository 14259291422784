.brand-img {
  max-width: 41px;
  height: auto;
}
.brand-name {
  max-width: 188px;
  height: auto;
}
//navbar
.navbar-light .navbar-nav {
  font-size: 0.75em;
}

.home-hero {
  height: 650px;
}

.home-hero-content {
  background: none;
  padding: 50px 60px;
  padding-right:100px !important;
}

.hero-subtitle {
  font-size: 1.25rem;
}

/* Merch Store */ 
#merch-banner {
  
}

/* Social Banner */ 
#socials-banner {
  position:relative;
}

.socials-banner-content {
  padding:2.75rem 4.375rem 2.75rem 40px;
}

.mk-icon-socials {
  max-width:52px;
  position:absolute;
  top:40px;
  right:40px;
}

/* Join Discord */
.nft-collection {
  grid-template-columns: 1fr 1fr 1fr;
}

#faqs {
  background-image: url(../images/fcats-graphic@2x.png);
  background-repeat: no-repeat;
  background-size: 300px auto;
  background-position: bottom right;
}

.card-slider.container-fluid {
  width: calc(((100% - 920px) / 2) + 920px) !important;
}

.rmAccordion .collapse {
  display: block;
}

/* Team Section */ 
.team-card {
  padding-top:0;
  
}

.team-content {
  padding: 4rem 2rem 3rem 0;
}

.team-avatar-wrapper {
  max-width: none;
  margin-left: 4rem;
  margin-right: 5rem;

  .team-avatar {
    max-width: 250px;
  }
}

//control dots
.rmnav-container {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
  li {
    display: inline-flex;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #f47901;
    cursor: pointer;
    margin: 0 5px;
  }
}
.card-slider {
  .card {
    min-height: 470px;
  }
  .team-name {
    font-size: 2.5rem;
    margin: 3rem 0;
  }
  .team-role {
    font-size: 1.25rem;
  }
  .team-bio {
    padding-right: 1rem;
  }
}

#road-map {
  .accordion-body {
    padding-left: 0;
    padding-right: 0;
  }
  #rmLeft {
    padding-right: 20px;
  }
  #rmRight {
    padding-left: 20px;
  }
}

#footer {
  .socials-nav {
    padding-top: 0;
    padding-bottom: 0;
  }
}
