.brand,
.socials,
.navbar-toggler-container {
  width: auto;
}

.socials-nav {
  li {
    margin-left: 5px;
    margin-right: 5px;
    a {
      width: 22px;
    }
  }
}

.navbar-expand-lg .navbar-nav {
  justify-content: center;
  flex-grow: 1;
  width: auto;
  margin: auto;

  .nav-link {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 0.875rem;
    font-family: $font-family-base;
  }
}

/*.home-hero {
  align-items: center;
  background-image: url(../images/mk-header-graphic@2x.png);
  background-size: contain;
  background-position: bottom right;
  height:calc(100vh - 100px);
}*/



.hero-subtitle {
  font-size: 1.5rem;
  line-height: 34px;
}

#socials-banner {
  background-image: url(../images/social-kat.jpg);
  background-repeat: no-repeat;
  background-size: 50% auto;
  background-position: bottom left;
  min-height: 60vh;
  display: flex;
  align-items: center;
  h2 {
    font-size: 3.125rem;
    line-height: 1;
  }
  h4 {
    font-size: 1.25rem;
    line-height: 1.4;
  }
}
.socials-banner-content {
  padding: 0;
}

#join-discord {
  position:relative;
}

.discord-wrapper {
  display: flex;
  min-height: 525px;
}

/* Merch Store */
.merch-promo { 
  padding-bottom: 4rem;
}

#merch-banner {

  .inner-content {
    padding:0;
  }
  
  h2 {
    font-size:3rem;
  }
  
}
.nft-collection {
  grid-template-columns: 1fr 1fr 1fr;
  position: absolute;
  right: 0;
  top: 0;
  width: 525px;
}

.card-slider {
  .tns-item .card {
    min-height: 550px;
  }
  .rm-title {
    font-size: 1.75rem;
  }
}
.card-slider.container-fluid {
  width: calc(((100% - 1150px) / 2) + 1150px) !important;
}

#footer {
  padding-top: 100px;
  .footer-graphic {
    max-width: 688px;
  }
}
